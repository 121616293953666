import {createApp} from 'vue'
import AppNewStudent from "@/App-NewStudent.vue";

// import css // TODO mhpws prepei na pane se config file?
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import '../../students-preregistration-frontend/src/assets/icomoon/style.css'


// import js
import 'bootstrap'
import {studentRouter as router} from "@/router";
import {createPinia} from "pinia";

const app = createApp(AppNewStudent)
app.use(createPinia())
app.use(router)
app.mount('#app')
