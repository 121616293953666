<script setup>
import LoginView from "@/views/newstudent/LoginView.vue"
import MainView from "@/views/newstudent/MainView.vue"
import {useNewStudentUserStore} from "@/stores/new-student-user"
import {BaseAPI, NewStudentAPI} from "@/util/api"
import {onMounted, onUnmounted, reactive, ref} from "vue"
import CopyrightNotice from "@/components/CopyrightNotice.vue"
import Toast from "@/components/Toast.vue"
import {UIMessages} from "@/util/ui-messages"
import {useRouter} from "vue-router"
import Loader from "@/components/Loader.vue"

const newStudentUserStore = useNewStudentUserStore()
const submissionPeriod = reactive({startDate: new Date(undefined), endDate: new Date(undefined)})
const toastMessage = ref('');
const toastTitle = ref('')
const toastDOM = ref(null)

const router = useRouter()
const isLoaderEnabled = ref(false)
let submissionPeriodTimerHandle;

function showErrorToast(message) {
  toastTitle.value = 'Αποτυχία'
  toastMessage.value = message
  toastDOM.value.show()
}

function logout() {
  //TODO add jwt invalidation
  newStudentUserStore.$reset()
}

function retrieveSubmissionPeriod() {
  isLoaderEnabled.value = true
  BaseAPI.getSubmissionPeriod().then(
      (apiResponse) => {
        if (apiResponse.responseStatus === NewStudentAPI.RESPONSE_STATUSES.SUCCESS) {
          submissionPeriod.startDate = new Date(apiResponse.body.startDate)
          submissionPeriod.endDate = new Date(apiResponse.body.endDate)
          if (submissionPeriod.startDate.getTime() > Date.now() || submissionPeriod.endDate.getTime() < Date.now()) {
            logout()
          } else {
            submissionPeriodTimerHandle = setTimeout(retrieveSubmissionPeriod, Math.max(submissionPeriod.endDate.getTime() - Date.now(), 0))
          }
        } else {
          showErrorToast(UIMessages[apiResponse.responseStatus])
        }
      }
  ).catch((error) => {
    showErrorToast(UIMessages.CONNECTION_ERROR)
  }).finally(() => {
    isLoaderEnabled.value = false
  })
}

onMounted(() => {
  retrieveSubmissionPeriod()
})

onUnmounted(() => {
  submissionPeriodTimerHandle()
})

router.beforeEach(async (to, from) => {
  if (to.name === "logout") {
    logout()
    return {name: "root"}
  }

  if (newStudentUserStore.isLoggedIn && to.name === "root") {
    return {name: "GridMenu"}
  }

  if (!newStudentUserStore.isLoggedIn && to.name !== "root") {
    return {name: "root"}
  }

  if (!to.name) {
    return !newStudentUserStore.isLoggedIn ? {name: "root"} : {name: "GridMenu"}
  }
})
</script>

<template>
  <template v-if="!Number.isNaN(submissionPeriod.startDate.getTime())">
    <template
        v-if="submissionPeriod.startDate.getTime() > Date.now() || submissionPeriod.endDate.getTime() < Date.now()">
      <div id="OutOfSubmissionPeriodInternalView" class="container">
        <div class="row justify-content-center">
          <img src="@/assets/unipi-logo.png" alt="Unipi Logo">
        </div>
        <h5 id="title" class="row mb-5 mt-4 justify-content-center">
          Εφαρμογή Κατάθεσης Δικαιολογητικών &amp; Ταυτοποίησης Πρωτοετών Φοιτητών
        </h5>
        <div class="card shadow">
          <div class="card-body d-flex p-1" style="text-align: justify">
            <div class="bi-info-square-fill me-3" style="font-size: 1.8rem; color: #419fde"></div>
            <div v-if="submissionPeriod.startDate.getTime() > Date.now()"> Η εφαρμογή κατάθεσης των δικαιολογητικών θα
              γίνει διαθέσιμη στις {{ submissionPeriod.startDate.toLocaleString("EL-GR") }}.
            </div>
            <div v-else> Η περίοδος κατάθεσης των δικαιολογητικών έχει παρέλθει. Για οποιαδήποτε πληροφορία
              επικοινωνήστε με τη γραμματεία του τμήματος σας.
            </div>
          </div>
        </div>
        <br/>
      </div>
      <CopyrightNotice class="fixed-bottom"/>
    </template>
    <LoginView v-else-if="!newStudentUserStore.isLoggedIn"/>
    <MainView v-else/>
  </template>

  <Toast ref="toastDOM">
    <template #title>
      {{ toastTitle }}
    </template>
    <template #message>
      {{ toastMessage }}
    </template>
  </Toast>
  <Loader :is-enabled="isLoaderEnabled"></Loader>

</template>

<style>
body {
  padding: 1px !important; /* to avoid margin collapsing*/
}
</style>

<style scoped>

#OutOfSubmissionPeriodInternalView {
  margin-top: 20px;
}


#OutOfSubmissionPeriodInternalView .row * {
  max-width: 400px;
}

#OutOfSubmissionPeriodInternalView label {
  margin-bottom: 5px;
}

#OutOfSubmissionPeriodInternalView button {
  margin-top: 20px;
}

#OutOfSubmissionPeriodInternalView .card {
  max-width: 500px;
  margin: auto;
  padding: 10px;
  border: 1px solid #4EA6DCFF;
}

#OutOfSubmissionPeriodInternalView.was-validated :valid {
  background-image: none;
  border: 1px solid #ced4da;
}

#OutOfSubmissionPeriodInternalView.was-validated :valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

#OutOfSubmissionPeriodInternalView #title {
  margin: auto;
  text-align: center;
  max-width: 500px;
  font-size: 1.1rem;
}
</style>