<script setup>
import {useNewStudentUserStore} from "@/stores/new-student-user"
import CopyrightNotice from "@/components/CopyrightNotice.vue"
import {onMounted, onUnmounted, ref} from "vue"
import {jwtDecode} from "jwt-decode"
import {studentRouter as router} from "@/router"
import {NewStudentAPI} from "@/util/api"
import {UIMessages} from "@/util/ui-messages"
import Loader from "@/components/Loader.vue"
import Toast from "@/components/Toast.vue"

const newStudentUserStore = useNewStudentUserStore()
const isLoaderEnabled = ref(false)
const toastDOM = ref(null)
const toastTitle = ref('')
const toastMessage = ref('')

let tokenCheckerHandler = null
let navigationHandlerRemover

async function loadNewStudentData() {
  isLoaderEnabled.value = true
  try {
    let apiResponse = await NewStudentAPI.getNewStudentInfo(newStudentUserStore.data.details.username, newStudentUserStore.data.token)
    if (apiResponse.responseStatus === NewStudentAPI.RESPONSE_STATUSES.SUCCESS) {
      newStudentUserStore.data.details = apiResponse.body
    } else if (apiResponse.responseStatus === NewStudentAPI.RESPONSE_STATUSES.AUTHENTICATION_FAILED) {
      newStudentUserStore.$reset()
    } else {
      showErrorToast(UIMessages[apiResponse.responseStatus])
    }
  } catch (error) {
    showErrorToast(UIMessages.CONNECTION_ERROR)
  } finally {
    isLoaderEnabled.value = false
  }
}

function showErrorToast(message) {
  toastTitle.value = 'Αποτυχία'
  toastMessage.value = message
  toastDOM.value.show()
}

onMounted(() => {
  navigationHandlerRemover = router.beforeEach(async (to, from) => {
    if (!newStudentUserStore.isLoggedIn) return // in case of logout

    if (to.name === "NewSubmission" || to.name === "SubmissionsHistory") {
      await loadNewStudentData()
    }
  })

  const checkToken = () => {
    let tokenExpirationDatetime = new Date(jwtDecode(newStudentUserStore.data.token).exp * 1000)
    let currentDate = new Date()

    if (tokenExpirationDatetime.getTime() <= currentDate.getTime()) {
      newStudentUserStore.$reset()
    } else {
      tokenCheckerHandler = setTimeout(checkToken, Math.max(tokenExpirationDatetime.getTime() - currentDate.getTime(), 0))
    }
  }

  checkToken()
  if (!newStudentUserStore.isLoggedIn) {
    return
  }

  loadNewStudentData()
  router.push("/menu")
})

onUnmounted(() => {
  navigationHandlerRemover()
  clearTimeout(tokenCheckerHandler)
})
</script>

<template>
  <header class="container-fluid px-0">
    <nav class="navbar bg-light">
      <div class="container-fluid px-2">
        <a href="https://www.unipi.gr" class="navbar-brand order-first" target="_blank">
          <img src="@/assets/unipi-logo.png" alt="Unipi Logo" style="width: 200px"/>
        </a>
        <div class="navbar-text order-lg-0 order-last">
          <h5 id="title">Εφαρμογή Κατάθεσης Δικαιολογητικών &amp; Ταυτοποίησης Πρωτοετών Φοιτητών</h5>
        </div>
        <div class="justify-content-end">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item dropdown">
              <span class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                {{ newStudentUserStore.data.details.firstName }} {{ newStudentUserStore.data.details.lastName }}
              </span>
              <ul class="dropdown-menu dropdown-menu-end position-absolute">
                <li>
                  <span class="dropdown-item" style="cursor: pointer" @click="router.push('/logout')"><i
                      class="icon-logout-fill"></i> Αποσύνδεση </span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
  <Loader :is-enabled="isLoaderEnabled"></Loader>
  <Toast ref="toastDOM">
    <template #title>
      {{ toastTitle }}
    </template>
    <template #message>
      {{ toastMessage }}
    </template>
  </Toast>
  <RouterView/>
  <CopyrightNotice/>
</template>

<style scoped>
@media (max-width: 992px) {
  .navbar-text {
    flex-basis: 100%;
    flex-grow: 1;
    text-align: center;
  }
}

#title {
  margin: auto;
  text-align: center;
  max-width: 500px;
  font-size: 1.1rem;
}
</style>