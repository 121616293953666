<script setup>
import {onBeforeUnmount, onMounted, onUnmounted, ref} from 'vue';
import {Datepicker} from 'vanillajs-datepicker';

const props = defineProps({
  modelValue: {
    type: String,
    required: false,
    default: ''
  },
  format: {
    type: String,
    required: false,
    default: 'dd/mm/yyyy'
  },
  isRequired: {
    type: Boolean,
    required: false,
    default: false
  },
  nativeInputId: {
    type: String,
    required: false,
  }
})

const emit = defineEmits(['update:modelValue'])

const dateFieldDOM = ref(null)
const focusOnDate = ref(false)
let datepicker;

function changeDateListener(event){
  emit('update:modelValue', event.target.value)
}

onMounted(() => {
  datepicker = new Datepicker(dateFieldDOM.value, {
    buttonClass: 'btn',
    format: props.format,
    autohide: true,
  });

  dateFieldDOM.value.addEventListener('changeDate', changeDateListener);
})

onBeforeUnmount(() => {
  dateFieldDOM.value.removeEventListener('changeDate', changeDateListener)
  datepicker.destroy()
})

</script>

<template>
  <div class="input-group">
    <span class="input-group-text">
    <i :class="{'bi-calendar2': !focusOnDate, 'bi-calendar2-fill': focusOnDate}"></i>
    </span>
    <input :id="props.nativeInputId" type="text" class="form-control" ref="dateFieldDOM"
           @focus="focusOnDate=true"
           @focusout="focusOnDate=false"
           :value="modelValue"
           :required="props.isRequired" @keydown.prevent>
    <div class="invalid-feedback" data-placement="right">
      Παρακαλώ εισάγετε ημερομηνία
    </div>
  </div>


</template>

<style>
@import 'vanillajs-datepicker/css/datepicker-bs5.css';
</style>