<script setup>
import {computed, nextTick, onBeforeUnmount, onMounted, ref, watch} from 'vue'
import {Modal} from 'bootstrap'

const props = defineProps({
  expirationDatetime: { // in UTC
    type: Date,
    required: true
  }
})

const emit = defineEmits(['otpInput', 'newOTPRequest', 'close', 'hide'])
const otpModalDOM = ref(null)
const focusOnOTP = ref(false)
const remainingTimeInSeconds = ref(0)
const remainingTimeInfo = computed(() => {
  return {
    minutes: Math.floor(remainingTimeInSeconds.value / 60),
    seconds: remainingTimeInSeconds.value % 60
  }
})
const otpValue = ref('')
const onHideListener = () => {
  emit('hide')
}
let timerIntervalHandle;

watch(remainingTimeInSeconds, () => {
  if (remainingTimeInSeconds.value === 0 && timerIntervalHandle) {
    clearInterval(timerIntervalHandle)
    timerIntervalHandle = null;
  }
})

onMounted(() => {
  Modal.getOrCreateInstance(otpModalDOM.value) // create instance
  otpModalDOM.value.addEventListener('hide.bs.modal', onHideListener)
})

onBeforeUnmount(() => {
  otpModalDOM.value.removeEventListener('hide.bs.modal', onHideListener)
  Modal.getInstance(otpModalDOM.value).hide();
  Modal.getInstance(otpModalDOM.value).dispose();

  if (timerIntervalHandle) {
    clearInterval(timerIntervalHandle)
  }
})

function _show() {
  let timerCallback = () => {
    let temp = Math.round((props.expirationDatetime.getTime() - Date.now()) / 1000)
    if (temp < 0) {
      remainingTimeInSeconds.value = 0
    } else {
      remainingTimeInSeconds.value = temp
    }
  }
  timerCallback()
  if (!timerIntervalHandle && remainingTimeInSeconds.value > 0) {
    timerIntervalHandle = setInterval(timerCallback, 1000)
  }

  otpValue.value = ''
  Modal.getInstance(otpModalDOM.value).show()
}

function show() {
  nextTick(_show)
}

function hide() {
  Modal.getInstance(otpModalDOM.value).hide()
  if (timerIntervalHandle) {
    clearInterval(timerIntervalHandle)
    timerIntervalHandle = null
  }
}

defineExpose({
  show,
  hide
})

</script>

<template>
  <div class="modal" tabindex="-1" ref="otpModalDOM" data-bs-backdrop="static">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Επαλήθευση OTP</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p class="text-center">Παρακαλώ εισάγετε τον κωδικό μιας χρήσης (One Time Password - OTP) που στάλθηκε στο
            e-mail σας.</p>
          <div class="row justify-content-center g-0">
            <div class="input-group">
              <span class="input-group-text"><i :class="{'bi-key': !focusOnOTP, 'bi-key-fill': focusOnOTP}"></i></span>
              <input type="text" class="form-control" @focus="focusOnOTP=true" @focusout="focusOnOTP=false"
                     @keyup.enter="() => {if(otpValue.trim() !== '' || remainingTimeInSeconds > 0) $emit('otpInput', otpValue)}"
                     v-model="otpValue" placeholder="OTP" required :disabled="remainingTimeInSeconds === 0">
            </div>
          </div>
          <div class="d-flex justify-content-between" style="font-size: 0.9em">
            <div class="p-2">Εναπομένων Χρόνος:
              <strong>
                {{ remainingTimeInfo.minutes.toString().padStart(2, '0') }}:
                {{ remainingTimeInfo.seconds.toString().padStart(2, '0') }}
              </strong>
            </div>
            <div class="p-2">
              <a href="#"
                 :class="{'opacity-25': remainingTimeInSeconds > 0}"
                 :style="{cursor: remainingTimeInSeconds > 0 ? 'not-allowed': 'pointer'}"
                 class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                 @click="() => {if(remainingTimeInSeconds === 0) $emit('newOTPRequest')}">Επαναποστολή OTP</a></div>
          </div>

          <div class="row justify-content-center g-0">
            <button class="btn btn-primary m-1" type="button" @click="$emit('otpInput', otpValue)"
                    :disabled="otpValue.trim() === '' || remainingTimeInSeconds === 0">Επαλήθευση
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>