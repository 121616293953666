<script setup>
import {reactive, ref} from "vue";
import {useNewStudentUserStore} from "@/stores/new-student-user";
import Loader from "@/components/Loader.vue";
import {NewStudentAPI} from '@/util/api'
import {UIMessages} from "@/util/ui-messages";
import OTPModal from "@/components/OTPModal.vue";
import CopyrightNotice from "@/components/CopyrightNotice.vue";
import DateInputField from "@/components/DateInputField.vue";
import Toast from "@/components/Toast.vue";

const NEW_STUDENT_MANUAL_URL = import.meta.env.BASE_URL + "/new_student_manual.pdf"
const newStudentUserStore = useNewStudentUserStore()

const focusOnExamNumber = ref(false)
const focusOnEmail = ref(false)
const studentBasicInfo = reactive({examNumber: "", email: "", birthDate: ""})
const loginFormDOM = ref(null)
const wasFormValidated = ref(false)
const isLoaderEnabled = ref(false)
let loginOtpData = reactive({
  id: null,
  expirationDate: new Date(),
  username: null
})


const toastDOM = ref(null)
const toastTitle = ref('')
const toastMessage = ref('');
const otpModalDOM = ref(null)

function showErrorToast(message) {
  toastTitle.value = 'Αποτυχία'
  toastMessage.value = message
  toastDOM.value.show()
}

function getLoginOTP() {
  otpModalDOM.value.hide()
  toastDOM.value.hide()
  isLoaderEnabled.value = true

  let isLoginFormValid = loginFormDOM.value.checkValidity()
  wasFormValidated.value = true

  if (!isLoginFormValid) {
    isLoaderEnabled.value = false
    return
  }

  NewStudentAPI.getLoginOTP(studentBasicInfo.examNumber, studentBasicInfo.email, studentBasicInfo.birthDate).then(
      (apiResponse) => {
        if (apiResponse.responseStatus === NewStudentAPI.RESPONSE_STATUSES.SUCCESS) {
          loginOtpData.id = apiResponse.body.id
          loginOtpData.username = apiResponse.body.username
          loginOtpData.expirationDate = new Date(apiResponse.body.expirationDate)
          otpModalDOM.value.show()
        } else if (apiResponse.responseStatus === NewStudentAPI.RESPONSE_STATUSES.AUTHENTICATION_FAILED) {
          showErrorToast(UIMessages.BAD_CREDENTIALS_NEW_STUDENT)
        } else {
          showErrorToast(UIMessages[apiResponse.responseStatus])
        }
      }
  ).catch((error) => {
    showErrorToast(UIMessages.CONNECTION_ERROR)
  }).finally(() => {
    isLoaderEnabled.value = false
  })
}

function login(otpValue) {
  isLoaderEnabled.value = true
  NewStudentAPI.login(loginOtpData.username, otpValue).then(
      (apiResponse) => {
        if (apiResponse.responseStatus === NewStudentAPI.RESPONSE_STATUSES.SUCCESS) {
          newStudentUserStore.data.token = apiResponse.body.token
          newStudentUserStore.data.details.username = loginOtpData.username
        } else if (apiResponse.responseStatus === NewStudentAPI.RESPONSE_STATUSES.AUTHENTICATION_FAILED) {
          showErrorToast(UIMessages.INVALID_OTP)
        } else {
          showErrorToast(UIMessages[apiResponse.responseStatus])
        }
      }
  ).catch((error) => {
    showErrorToast(UIMessages.CONNECTION_ERROR)
  }).finally(() => {
    isLoaderEnabled.value = false
  })
}
</script>

<template>
  <form id="loginForm" class="container" :class="{'was-validated': wasFormValidated}" @keyup.enter="getLoginOTP"
        ref="loginFormDOM">
    <div class="row justify-content-center">
      <img src="@/assets/unipi-logo.png" alt="Unipi Logo">
    </div>
    <h5 id="title" class="row mb-5 mt-4 justify-content-center">
      Εφαρμογή Κατάθεσης Δικαιολογητικών &amp; Ταυτοποίησης Πρωτοετών Φοιτητών
    </h5>
    <div class="card shadow">
      <div class="card-body">
        <div class="row justify-content-center g-0">
          <label for="examNumber" class="form-label">Kωδικός Υποψηφίου:</label>
        </div>
        <div class="row justify-content-center g-0">
          <div class="input-group">
            <span class="input-group-text">
                <i :class="{'bi-person': !focusOnExamNumber, 'bi-person-fill': focusOnExamNumber}"></i>
            </span>
            <input type="text" class="form-control" aria-label="Exam Number" id="examNumber"
                   @focus="focusOnExamNumber=true"
                   @focusout="focusOnExamNumber=false" v-model="studentBasicInfo.examNumber" pattern="\d{8}" required>
            <div class="invalid-feedback" data-placement="right">
              Παρακαλώ εισάγετε έγκυρο κωδικό υποψηφίου
            </div>
          </div>
        </div>
        <div class="row justify-content-center g-0" style="margin-top: 10px">
          <label for="email" class="form-label">E-mail:</label>
        </div>
        <div class="row justify-content-center g-0">
          <div class="input-group">
            <span class="input-group-text">
                <i :class="{'bi-envelope-at': !focusOnEmail, 'bi-envelope-at-fill': focusOnEmail}"></i>
            </span>
            <input type="text" class="form-control" aria-label="Email" id="email"
                   @focus="focusOnEmail=true" @focusout="focusOnEmail=false" v-model="studentBasicInfo.email"
                   pattern="[^@\s]+@[^@\s]+\.[^@\s]+" required>
            <div class="invalid-feedback" data-placement="right">
              Παρακαλώ εισάγετε έγκυρο email
            </div>
          </div>
        </div>
        <div class="row justify-content-center g-0" style="margin-top: 10px">
          <label for="birthDate" class="form-label">Ημερομηνία Γέννησης:</label>
        </div>
        <div class="row justify-content-center g-0">
          <DateInputField native-input-id="birthDate" v-model="studentBasicInfo.birthDate"
                          :is-required="true"></DateInputField>
        </div>
        <div class="row justify-content-center g-0 mt-2">
          <button class="btn btn-secondary m-1" type="button" @click="getLoginOTP">Είσοδος</button>
        </div>
      </div>
    </div>
    <br/>
    <div class="row justify-content-center mt-1">
      <a :href="NEW_STUDENT_MANUAL_URL" class="text-center link-primary" target="_blank"
         download="Οδηγός χρήσης εφαρμογής προεγγραφής φοιτητών.pdf">
        <b>Οδηγίες χρήσης εφαρμογής</b></a>
    </div>
    <CopyrightNotice/>
  </form>

  <Toast ref="toastDOM">
    <template #title>
      {{ toastTitle }}
    </template>
    <template #message>
      <div v-if="toastMessage === UIMessages.BAD_CREDENTIALS_NEW_STUDENT" v-html="toastMessage"></div>
      <div v-else>{{ toastMessage }}</div>
    </template>
  </Toast>
  <OTPModal :expiration-datetime="loginOtpData.expirationDate" @otpInput="login" ref="otpModalDOM"
            @hide="toastDOM.hide()"
            @newOTPRequest="getLoginOTP"></OTPModal>
  <Loader :is-enabled="isLoaderEnabled"></Loader>
</template>

<style scoped>

#loginForm {
  margin-top: 20px;
}


#loginForm .row * {
  max-width: 400px;
}

#loginForm label {
  margin-bottom: 5px;
}

#loginForm button {
  margin-top: 20px;
}

#loginForm .card {
  max-width: 500px;
  margin: auto;
  padding: 10px;
  border: 1px solid #4EA6DCFF;
}

#loginForm.was-validated :valid {
  background-image: none;
  border: 1px solid #ced4da;
}

#loginForm.was-validated :valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

#loginForm #title {
  margin: auto;
  text-align: center;
  max-width: 500px;
  font-size: 1.1rem;
}
</style>